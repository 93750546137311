import HomeStatus from '$/containers/Views/Home/types';
import { CurrentLeaseStatus } from '$/model/LeaseStatus';
import {
  ExternalRenewalStatus,
  InternalRenewalStatus,
} from '$/model/RenewalStatus';
import { UpgradeStatus } from '$/model/UpgradeStatus';
import { isPast, isToday } from 'date-fns';

import { Props, ReturnProps } from './types';

const getBaseStatus = (
  currentLease: Props['currentLease'],
  currentRenewalOffer: Props['currentRenewalOffer'],
  upgradeStatus: Props['upgradeStatus'],
) => {
  const renewalDeadline = new Date(currentRenewalOffer?.effectiveTo || '');

  return {
    renewalStatus: currentRenewalOffer?.status,
    upgradeStatus,
    m2mActive: currentLease?.monthToMonth,
    leaseStatus: currentLease?.status,
    renewalExpired:
      !!renewalDeadline && !isToday(renewalDeadline) && isPast(renewalDeadline),
  };
};

const statusMap: Record<string, HomeStatus> = {
  [`UPGRADE_${UpgradeStatus.INTERESTED}`]: HomeStatus.UpgradeInterested,
  [`${CurrentLeaseStatus.NEW},${CurrentLeaseStatus.RENEWED}`]:
    HomeStatus.NewLeaseAccepted,
  [InternalRenewalStatus.OFFERED]: HomeStatus.RenewalOffered,
  [InternalRenewalStatus.DECLINED]: HomeStatus.RenewalDeclined,
  [InternalRenewalStatus.INTERESTED_LATE]: HomeStatus.RenewalInterestedLate,
  [InternalRenewalStatus.INTERESTED]: HomeStatus.RenewalInterested,
  [InternalRenewalStatus.NOT_OFFERED]: HomeStatus.RenewalNotOffered,
  [ExternalRenewalStatus.DECLINED]: HomeStatus.ExternalRenewalDeclined,
  [ExternalRenewalStatus.INTERESTED]: HomeStatus.ExternalRenewalInterested,
};

const useAppStatus = ({
  upgradeStatus,
  currentLease,
  currentRenewalOffer,
}: Props): ReturnProps => {
  if (!upgradeStatus || !currentLease || !currentRenewalOffer) return {};

  const baseStatus = getBaseStatus(
    currentLease,
    currentRenewalOffer,
    upgradeStatus,
  );

  if (upgradeStatus === UpgradeStatus.INTERESTED) {
    return { ...baseStatus, homeStatus: statusMap[`UPGRADE_${upgradeStatus}`] };
  }

  const hasNewLease = [
    CurrentLeaseStatus.NEW,
    CurrentLeaseStatus.RENEWED,
  ].includes(currentLease.status);
  if (hasNewLease) {
    return {
      ...baseStatus,
      homeStatus:
        statusMap[`${CurrentLeaseStatus.NEW},${CurrentLeaseStatus.RENEWED}`],
    };
  }

  const renewalStatus = currentRenewalOffer.status;
  return {
    ...baseStatus,
    homeStatus: statusMap[renewalStatus] || undefined,
  };
};

export default useAppStatus;
