import {
  RenewalOfferDecisionType,
  RenewalOfferStatus,
} from '$/graphql/generated';

export enum InternalRenewalStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  INTERESTED = 'INTERESTED',
  INTERESTED_LATE = 'INTERESTED_LATE',
  OFFERED = 'OFFERED',
  NOT_OFFERED = 'NOT_OFFERED',
}

export enum ExternalRenewalStatus {
  DECLINED = 'EXTERNAL_DECLINED',
  INTERESTED = 'EXTERNAL_INTERESTED',
}

export type RenewalStatus = InternalRenewalStatus | ExternalRenewalStatus;

export const normalizeRenewalStatus = (
  input: RenewalOfferStatus | undefined,
  type: 'ExternalRenewalOfferDecision' | 'RenewalOfferDecision' | undefined,
  decision?: RenewalOfferDecisionType,
): RenewalStatus => {
  const isExternal = type === 'ExternalRenewalOfferDecision';
  if (isExternal) {
    // if it is an external renewal offer decision, then the status is either DECLINED or INTERESTED
    return decision === RenewalOfferDecisionType.Declined
      ? ExternalRenewalStatus.DECLINED
      : ExternalRenewalStatus.INTERESTED;
  }
  const statusMap = {
    [RenewalOfferStatus.Accepted]: InternalRenewalStatus.ACCEPTED,
    [RenewalOfferStatus.Declined]: InternalRenewalStatus.DECLINED,
    [RenewalOfferStatus.Interested]: InternalRenewalStatus.INTERESTED,
    [RenewalOfferStatus.InterestedLate]: InternalRenewalStatus.INTERESTED_LATE,
    [RenewalOfferStatus.Offered]: InternalRenewalStatus.OFFERED,
    [RenewalOfferStatus.NotOffered]: InternalRenewalStatus.NOT_OFFERED,
  };

  return statusMap[input ?? RenewalOfferStatus.NotOffered];
};

export const isDeclined = (status?: RenewalStatus | string): boolean =>
  status === InternalRenewalStatus.DECLINED ||
  status === ExternalRenewalStatus.DECLINED;

export const isInternalInterested = (
  status?: RenewalStatus | string,
): boolean =>
  [
    InternalRenewalStatus.INTERESTED,
    InternalRenewalStatus.INTERESTED_LATE,
  ].includes(status as InternalRenewalStatus);

export const isInterestedOrLate = (status?: RenewalStatus | string): boolean =>
  isInternalInterested(status) || status === ExternalRenewalStatus.INTERESTED;

export const isInterested = (status?: RenewalStatus | string): boolean =>
  status === InternalRenewalStatus.INTERESTED ||
  status === ExternalRenewalStatus.INTERESTED;
