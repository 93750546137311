import type { RenewalOfferFragment } from '$/graphql/generated';
import { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeRenewalPricing from './RenewalPricing';
import normalizeRenewalTerm from './RenewalTerm';
import normalizeUserSummary from './UserSummary';

export default function normalizeRenewalDecision(
  decision: WithoutNulls<RenewalOfferFragment['decision'] | undefined>,
) {
  let decisionInfo;

  if (decision?.__typename === 'ExternalRenewalOfferDecision') {
    decisionInfo = {
      externalDecision: true,
      decision: decision.decision,
      id: decision.id,
      externalInterestedTerm: decision.externalInterestedTerm,
      decidedAt: decision.externalDecidedAt,
    };
  }
  if (decision?.__typename === 'RenewalOfferDecision') {
    decisionInfo = {
      externalDecision: false,
      decision: decision.decision,
      interestedTerm: decision.interestedTerm,
      decidedAt: decision.decidedAt,
      eSignature: decision.eSignature,
      id: decision.id,
      decidedByUser: decision.decidedByUser,
      pricing: decision.pricing,
    };
  }

  if (!decisionInfo) {
    return undefined;
  }
  return {
    id: decisionInfo?.id,
    eSignature: decisionInfo?.eSignature || undefined,
    externalDecision: decisionInfo?.externalDecision,
    decidedAt: decisionInfo?.decidedAt
      ? parseISO(decisionInfo.decidedAt).getTime()
      : undefined,
    decidedByUser: decisionInfo?.decidedByUser
      ? normalizeUserSummary(decisionInfo.decidedByUser)
      : undefined,
    decision: decisionInfo?.decision || undefined,
    interestedTerm: decisionInfo?.interestedTerm
      ? normalizeRenewalTerm(decisionInfo.interestedTerm)
      : undefined,
    externalInterestedTerm: decisionInfo?.externalInterestedTerm || undefined,
    pricing: decisionInfo?.pricing
      ? normalizeRenewalPricing(decisionInfo.pricing)
      : undefined,
  };
}

export type RenewalOfferDecision = ReturnType<typeof normalizeRenewalDecision>;
