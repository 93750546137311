import type { ChargeFragment } from '$/graphql/generated';

import { normalizeChargeFrequency } from './ChargeFrequency';
import { normalizeChargeType } from './ChargeType';

export default function normalizeCharge(input: ChargeFragment) {
  return {
    id: input.id,
    name: input.name,
    amount: input.amount,
    frequency: normalizeChargeFrequency(input.frequency),
    type: normalizeChargeType(input.type),
  };
}

export type Charge = ReturnType<typeof normalizeCharge>;
