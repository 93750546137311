import type { RenewalOfferFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeRenewalDecision from './RenewalDecision';
import normalizeRenewalPricings from './RenewalPricings';
import { normalizeRenewalStatus } from './RenewalStatus';

export default function normalizeRenewalOffer(
  input: WithoutNulls<RenewalOfferFragment>,
) {
  return {
    id: input.id,
    status: normalizeRenewalStatus(
      input.status,
      input.decision?.__typename,
      input.decision?.decision,
    ),
    effectiveFrom: parseISO(input.effectiveFrom).getTime(),
    effectiveTo: parseISO(input.effectiveTo).getTime(),
    decision: normalizeRenewalDecision(input.decision),
    pricings: normalizeRenewalPricings(input.pricings),
  };
}

export type RenewalOffer = ReturnType<typeof normalizeRenewalOffer>;
